import styled from 'styled-components'

const Input = styled.input`
  border: none;
  border-bottom: 2px solid black;
  font-size: 17px;
  outline: none;
  transition: 0.3s ease;

  &:not(:placeholder-shown):invalid {
    border-color: red;
  }
`

export { Input }
