import styled from 'styled-components'

const List = styled.ul`
  width: inherit;
  list-style: none;
  border: 1px solid black;
  border-radius: 5px;
`

const ListItem = styled.li`
  padding: 15px;
  display: flex;
  align-items: center;
  transition: 0.15s ease-in;
  user-select: none;

  &:not(:last-child) {
    border-bottom: 1px solid black;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`

export { List, ListItem }
