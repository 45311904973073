import { useState, FC, FormEventHandler, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Form, Button, Input, RouteLink } from 'components'
import { LoginContainer } from 'pages/Login/styles'
import { login } from 'actions/login'
import { handleValueChange } from 'utils'

const Login: FC = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { redirectUrl } = useParams()

  useEffect(() => {
    if (redirectUrl) {
      localStorage.setItem('redirectUrl', redirectUrl)
    } else {
      localStorage.removeItem('redirectUrl')
    }
  }, [redirectUrl])

  const handleLogin: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    login({ email, password })
      .then(user => {
        toast('Login successful', { type: 'success' })
        navigate('/multi-factor', { state: user })
      })
      .catch(_ => toast('Email or password is invalid!', { type: 'error' }))
  }

  return (
    <LoginContainer>
      <img
        alt="Company logo"
        height={125}
        width={125}
        style={{ alignSelf: 'center', marginBottom: 35 }}
        src={require('../../images/DS_Black_Icon150.png')}
      />
      <Form onSubmit={handleLogin}>
        <Input
          type="email"
          placeholder="Email"
          value={email}
          autoComplete="username"
          spellCheck={false}
          onChange={e => handleValueChange(e, setEmail)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={e => handleValueChange(e, setPassword)}
          required
        />
        <Button style={{ marginTop: 45 }}>Login</Button>
      </Form>
      <RouteLink to="/register" style={{ alignSelf: 'center', marginTop: 15 }}>
        Create Account
      </RouteLink>
    </LoginContainer>
  )
}

export default Login
