import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import type { MultiFactorLogin } from 'types'

const useMultiFactorData = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const [multiFactorData, setMultiFactorData] = useState<MultiFactorLogin>()

  useEffect(() => {
    if (state === null) navigate('/', { replace: true })
    setMultiFactorData(state as MultiFactorLogin)
  }, [state, navigate])

  // MFA data are derived from browser history state
  // We need to clean the state as it is kept on page reloads
  useEffect(() => window.history.replaceState({}, document.title), [])

  return multiFactorData
}

export { useMultiFactorData }
