import styled from 'styled-components'

const Form = styled.form`
  display: flex;
  flex-direction: column;

  & > input {
    padding: 10px 5px;
  }

  & > * {
    margin: 10px 0;
  }
`

export { Form }
