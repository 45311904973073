import { MultiFactorLogin } from 'types'
import { AUTH_API_HOST } from 'const'

const login = async ({ email, password }: any): Promise<MultiFactorLogin> => {
  const loginRequest = new Request(`${AUTH_API_HOST}/api/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
    headers: {
      'Content-type': 'application/json'
    }
  })

  const response = await fetch(loginRequest)
  if (!response.ok) throw new Error('Login Failed')

  return response.json()
}

const loginUsingToken = (token: string, appHost: string) => {
  const redirect = new URL(appHost)
  const params = new URLSearchParams({ code: token })

  redirect.search = params.toString()
  window.location.href = redirect.toString()
}

export { login, loginUsingToken }
