import { AUTH_API_HOST } from 'const'

interface CreateUserInput {
  title: string
  email: string
  password: string
  firstName: string
  lastName: string
  telephoneNumber: string
  uuid?: string
}

const createUser = ({
  title,
  email,
  password,
  firstName,
  lastName,
  telephoneNumber,
  uuid
}: CreateUserInput) => {
  const loginRequest = new Request(`${AUTH_API_HOST}/api/user`, {
    method: 'POST',
    body: JSON.stringify({
      title,
      email,
      password,
      firstName,
      lastName,
      telephoneNumber,
      passwordResetQuestion: 'What is your employee ID number?',
      passwordResetAnswer: '777',
      uuid
    }),
    headers: {
      'Content-type': 'application/json'
    }
  })

  return fetch(loginRequest)
}

export { createUser }
