import { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { List, ListItem, Countdown } from 'components'
import {
  sendMultiFactorCode,
  validateMultiFactorCode
} from 'actions/mutliFactor'
import { loginUsingToken } from 'actions/login'
import { strategyIcons } from 'pages/MultiFactor/StrategyIcons'
import { Validate } from 'pages/MultiFactor/Components'
import { useMultiFactorData } from 'pages/MultiFactor/useMultiFactorData'
import { ValidationState } from 'pages/MultiFactor/validationState'
import { MultiFactorContainer } from 'pages/MultiFactor/styles'
import { MultiFactorStrategy } from 'types'
import { isValidUrl } from './utils'

const MultiFactor: FC = () => {
  const navigate = useNavigate()
  const multiFactorData = useMultiFactorData()
  const [validationState, setValidationState] = useState<ValidationState>(
    ValidationState.UNSET
  )
  const [isMfaCodeSent, setIsMfaCodeSent] = useState(false)

  if (!multiFactorData) return null

  const { factors, exp, token } = multiFactorData

  const handleSendMultiFactorCode = (strategy: MultiFactorStrategy) =>
    sendMultiFactorCode(token, strategy)
      .then(() => {
        setValidationState(ValidationState.UNSET)
        toast(`Your login code has been sent via ${strategy}`, {
          type: 'success'
        })
        setIsMfaCodeSent(true)
      })
      .catch(error => {
        console.error(error)
        navigate('/')
        toast(
          `Failed to send login code via ${strategy}, please try again later`,
          {
            type: 'error'
          }
        )
      })

  const handleValidateMultiFactorCode = (code: string) => {
    if (validationState === ValidationState.VALID) return

    setValidationState(ValidationState.VALIDATING)

    validateMultiFactorCode(token, MultiFactorStrategy.Email, code)
      .then(token => {
        const redirectTimeout = 2500

        setValidationState(ValidationState.VALID)
        toast('Login completed! Redirecting to App', {
          type: 'success',
          autoClose: redirectTimeout
        })
        const url = localStorage.getItem('redirectUrl')
        let redirectUrl = url && isValidUrl(url) ? url : undefined

        setTimeout(
          redirectUrl
            ? () => loginUsingToken(token, redirectUrl!)
            : () => navigate(`/select-app/${token}`),
          redirectTimeout
        )
      })
      .catch(error => {
        setValidationState(ValidationState.FAILED)
        console.error(error)
      })
  }

  const handleMultiFactorSessionExpired = () => {
    const shouldNotRedirect =
      validationState === ValidationState.VALIDATING ||
      validationState === ValidationState.VALID

    if (shouldNotRedirect) return

    toast('Times up! Please login again and confirm your identity', {
      type: 'warning'
    })
    navigate('/')
  }

  const countdownDuration = new Date(exp).getTime() - new Date().getTime()

  return (
    <MultiFactorContainer>
      <Countdown
        duration={countdownDuration}
        style={{ marginBottom: 25 }}
        onEnd={handleMultiFactorSessionExpired}
        cancel={validationState === ValidationState.VALID}
        cancelText="🔓"
      />
      <div
        style={{
          marginBottom: 25,
          textAlign: 'center',
          fontSize: 27,
          fontWeight: 500
        }}
      >
        Two-Factor Authentication
      </div>
      {isMfaCodeSent ? (
        <Validate
          codeLength={6}
          isValid={validationState === ValidationState.VALID}
          onValidate={code => handleValidateMultiFactorCode(code)}
          onRetry={() => setIsMfaCodeSent(false)}
        />
      ) : (
        <List>
          {factors.map((factor, index) => {
            const Icon = strategyIcons[factor]

            return (
              <ListItem
                key={index}
                onClick={() => handleSendMultiFactorCode(factor)}
              >
                <Icon size={23} />
                <p style={{ paddingLeft: 10 }}>Sign in via {factor}</p>
              </ListItem>
            )
          })}
        </List>
      )}
    </MultiFactorContainer>
  )
}

export default MultiFactor
