import { StrictMode, FC } from 'react'
import { AppRouter } from 'Router'
import { Layout } from 'components/Layout'
import { GlobalStyle } from 'Reset'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const App: FC = () => (
  <StrictMode>
    <GlobalStyle />
    <Layout>
      <AppRouter />
    </Layout>
    <ToastContainer />
  </StrictMode>
)

export default App
