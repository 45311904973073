import styled from 'styled-components'

const Select = styled.select`
  background: white;
  height: 35px;
  border: none;
  border-bottom: 2px solid black;
  font-size: 17px;
  outline: none;
`

export { Select }
