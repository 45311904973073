import styled from 'styled-components'
import { Link as AnchorLink } from 'react-router-dom'

const Link = styled.a`
  color: black;
  text-decoration: underline;
  user-select: none;

  &:active,
  &:focus {
  }
`

const RouteLink = styled(AnchorLink)`
  color: black;
  text-decoration: underline;
  user-select: none;

  &:active,
  &:focus {
  }
`

export { Link, RouteLink }
