import { useState, FC, FormEventHandler, useRef, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Input, RouteLink, Select } from 'components'
import { RegisterContainer } from 'pages/Register/styles'
import { createUser } from 'actions/user'
import { handleValueChange } from 'utils'
import { toast } from 'react-toastify'

const CONFIRM_PASSWORD_NOT_MATCH = 'Password & confirm password does not match'

const TITLES = ['Mr', 'Mrs', 'Miss', 'Ms', 'Mx', 'Dr', 'Prof']

const Register: FC = () => {
  const navigate = useNavigate()
  const { uuid } = useParams()
  const passwordConfirmRef = useRef<HTMLInputElement | null>(null)

  const [title, setTitle] = useState<string>(TITLES[0])
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [telephoneNumber, setTelephoneNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')

  useEffect(() => {
    const passwordConfirmElement = passwordConfirmRef.current
    if (!passwordConfirmElement) return

    const doesPasswordMatch = password === passwordConfirm
    const validationMessage = doesPasswordMatch
      ? ''
      : CONFIRM_PASSWORD_NOT_MATCH

    passwordConfirmElement.setCustomValidity(validationMessage)
  }, [passwordConfirmRef, password, passwordConfirm])

  const handleRegister: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault()

    if (!uuid) {
      toast.error('Invalid registration link')
      return
    }

    createUser({
      title,
      email,
      password,
      firstName,
      lastName,
      telephoneNumber,
      uuid
    })
      .then(res => {
        if (res.status !== 201) throw res.json()

        return res.text()
      })
      .then(() => {
        navigate('/')
        toast(`Your account was successfully created ${firstName}`, {
          type: 'success'
        })
      })
      .catch(console.error)
  }

  return (
    <RegisterContainer>
      <img
        alt="Company logo"
        height={125}
        width={125}
        src={require('../../images/DS_Black_Icon150.png')}
        style={{ alignSelf: 'center', marginBottom: 35 }}
      />
      <Form onSubmit={handleRegister}>
        <Select
          required
          value={title}
          onChange={e => handleValueChange(e, setTitle)}
        >
          {TITLES.map(title => (
            <option value={title} key={title}>
              {title}
            </option>
          ))}
        </Select>
        <Input
          placeholder="First Name"
          value={firstName}
          spellCheck={false}
          onChange={e => handleValueChange(e, setFirstName)}
          required
        />
        <Input
          placeholder="Last Name"
          value={lastName}
          spellCheck={false}
          onChange={e => handleValueChange(e, setLastName)}
          required
        />
        <Input
          type="email"
          placeholder="Email"
          value={email}
          autoComplete="username"
          spellCheck={false}
          onChange={e => handleValueChange(e, setEmail)}
          required
        />
        <Input
          type="tel"
          placeholder="Phone Number"
          pattern="^[0-9+\(\)#\.\s\/ext-]+$"
          value={telephoneNumber}
          onChange={e => handleValueChange(e, setTelephoneNumber)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          autoComplete="new-password"
          onChange={e => handleValueChange(e, setPassword)}
          required
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          value={passwordConfirm}
          autoComplete="new-password"
          ref={passwordConfirmRef}
          onChange={e => handleValueChange(e, setPasswordConfirm)}
          required
        />
        <Button style={{ marginTop: 45 }}>Register</Button>
      </Form>
      <RouteLink to="/login" style={{ alignSelf: 'center', marginTop: 15 }}>
        Back to login
      </RouteLink>
    </RegisterContainer>
  )
}

export default Register
