export interface RedirectOptionDecsription {
  label: string
  url: string
  logo: string
}

export interface RedirectOptions {
  [key: string]: RedirectOptionDecsription[]
}

const NODE_ENV = process.env.NODE_ENV || 'development'

const redirectOptions: RedirectOptions = {
  development: [
    {
      label: 'Customer Platform',
      url: 'https://dev.app.aufzughelden.com',
      logo: 'logourl'
    },
    {
      label: 'Data Dashboard',
      url: 'https://dev.data.aufzughelden.com',
      logo: 'logourl'
    }
  ],
  production: [
    {
      label: 'Customer Platform',
      url: 'https://app.aufzughelden.com',
      logo: 'logourl'
    },
    {
      label: 'Data Dashboard',
      url: 'https://data.aufzughelden.com',
      logo: 'logourl'
    }
  ],
  test: [
    {
      label: 'Customer Platform',
      url: 'https://test.app.aufzughelden.com',
      logo: 'logourl'
    },
    {
      label: 'Data Dashboard',
      url: 'https://test.data.aufzughelden.com',
      logo: 'logourl'
    }
  ]
}

export { redirectOptions, NODE_ENV }
