import { FC, MouseEventHandler } from 'react'
import { Form, Input, Link } from 'components'

interface IValidate {
  codeLength: number
  onValidate: (code: string) => void
  isValid: boolean
  onRetry: MouseEventHandler<HTMLAnchorElement>
}

const Validate: FC<IValidate> = ({
  codeLength,
  onValidate,
  isValid,
  onRetry
}) => {
  return (
    <Form onSubmit={e => e.preventDefault()}>
      <Input
        style={{
          fontSize: 25,
          fontWeight: 200
        }}
        autoFocus={true}
        minLength={codeLength}
        maxLength={codeLength + 1}
        pattern="[0-9- ]*"
        inputMode="numeric"
        autoComplete="one-time-code"
        placeholder="Code"
        disabled={isValid}
        onChange={({ currentTarget: { validity, value } }) => {
          if (isValid) return

          const cleanedValue = value.replaceAll('-', '').replaceAll(' ', '')

          const isInputValid =
            validity.valid &&
            !validity.tooShort &&
            cleanedValue.length === codeLength

          isInputValid && onValidate(cleanedValue)
        }}
      />
      <Link
        onClick={e => !isValid && onRetry(e)}
        style={{
          textAlign: 'center',
          color: isValid ? '#00000069' : '#000',
          cursor: isValid ? 'not-allowed' : ''
        }}
      >
        Code not received? Try again &#x21bb;
      </Link>
    </Form>
  )
}

export { Validate }
