import styled from 'styled-components'

const RegisterContainer = styled.div`
  display: flex;
  width: 450px;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  justify-content: center;
  padding: 20px 0;
`

export { RegisterContainer }
