import { MultiFactorStrategy } from 'types'

// Returns all MFA implementations the user has not
// configured in order to allow the user to set it up
const userNoneConfiguredMultiFactors = (
  userAuthFactors: MultiFactorStrategy[]
): MultiFactorStrategy[] => {
  const noneConfigedMultiFactors: MultiFactorStrategy[] = []

  for (const strategy in MultiFactorStrategy) {
    const hasUserConfiguredStrategy = userAuthFactors.includes(
      strategy as MultiFactorStrategy
    )

    if (!hasUserConfiguredStrategy)
      noneConfigedMultiFactors.push(strategy as MultiFactorStrategy)
  }

  return noneConfigedMultiFactors
}

const isValidUrl = (url: string) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}

export { userNoneConfiguredMultiFactors, isValidUrl }
