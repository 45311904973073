import { BrowserRouter, RouteObject, useRoutes } from 'react-router-dom'
import Login from 'pages/Login'
import Register from 'pages/Register'
import MultiFactor from 'pages/MultiFactor'
import ValidateEmail from 'pages/ValidateEmail'
import SelectApp from 'pages/SelectApp'

const routes: RouteObject[] = [
  { path: '/multi-factor', element: <MultiFactor /> },
  { path: '/register', element: <Register /> },
  { path: '/register/:uuid', element: <Register /> },
  { path: '/login', element: <Login /> },
  { path: '/validate-email', element: <ValidateEmail /> },
  { path: '/select-app', element: <SelectApp /> },
  { path: '/select-app/:token', element: <SelectApp /> },
  { path: '/', element: <Login /> },
  { path: '/:redirectUrl', element: <Login /> },
  { path: '*', element: <h1>404</h1> }
]

const AppRouter = () => {
  const Router = () => useRoutes(routes)

  return <BrowserRouter children={<Router />} />
}

export { AppRouter }
