import { useParams } from 'react-router-dom'
import { NODE_ENV, RedirectOptionDecsription, redirectOptions } from './consts'
import {
  SelectAppContainer,
  SelectAppLink,
  SelectAppLinkContainer
} from './styles'

const SelectApp = () => {
  const { token } = useParams<{ token: string }>()
  return (
    <SelectAppContainer>
      {redirectOptions[NODE_ENV].map(
        ({ label, url, logo }: RedirectOptionDecsription) => (
          <SelectAppLink href={token ? `${url}?token=${token}` : url}>
            <SelectAppLinkContainer key={label}>{label}</SelectAppLinkContainer>
          </SelectAppLink>
        )
      )}
    </SelectAppContainer>
  )
}

export default SelectApp
