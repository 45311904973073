import { useEffect, useState, FC } from 'react'
import styled, { CSSProperties } from 'styled-components'
import { formatMsToMinuteSeconds } from 'components/Countdown/utils'

const CountdownContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CountdownTimerText = styled.div`
  color: white;
  font-weight: 600;
  font-size: 23px;
`

interface CountdownI {
  duration: number
  onEnd?: Function
  style: CSSProperties
  cancel?: boolean
  cancelText?: string
}

const Countdown: FC<CountdownI> = ({
  duration,
  onEnd,
  cancel,
  cancelText = '',
  style
}) => {
  const [countdownEnd] = useState(new Date(Date.now() + duration).getTime())
  const [timeRemainingMs, setTimeRemainingMs] = useState(
    countdownEnd - Date.now()
  )

  useEffect(() => {
    const calcInterval = setInterval(() => {
      const remaingingMs = countdownEnd - Date.now()

      if (remaingingMs <= 0) {
        if (typeof onEnd === 'function') onEnd()
        clearInterval(calcInterval)
      }

      setTimeRemainingMs(remaingingMs)
    }, 500)

    if (cancel) clearInterval(calcInterval)

    return () => clearInterval(calcInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, onEnd, cancel])

  return (
    <CountdownContainer style={style}>
      <CountdownTimerText>
        {cancel ? cancelText : formatMsToMinuteSeconds(timeRemainingMs)}
      </CountdownTimerText>
    </CountdownContainer>
  )
}

export { Countdown }
