import styled from 'styled-components'

const Button = styled.button`
  border: none;
  border: 2px solid black;
  font-weight: 500;
  font-size: 18px;
  color: white;
  background-color: black;
  cursor: pointer;
  padding: 15px 10px;
  border-radius: 5px;
  transition: 0.3s ease;
  user-select: none;

  &:hover {
    color: black;
    background-color: white;
    border-color: black;
  }
`

export { Button }
