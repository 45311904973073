import { AUTH_API_HOST } from 'const'
import { MultiFactorStrategy } from 'types'

const sendMultiFactorCode = async (
  token: string,
  strategy: MultiFactorStrategy
) => {
  const response = await fetch(`${AUTH_API_HOST}/api/multi-factor/send`, {
    method: 'POST',
    body: JSON.stringify({ token, strategy }),
    headers: { 'Content-Type': 'application/json' }
  })

  if (!response.ok) throw new Error('An error has occurred')
}

const validateMultiFactorCode = async (
  token: string,
  strategy: MultiFactorStrategy,
  code: string
): Promise<string> => {
  const response = await fetch(`${AUTH_API_HOST}/api/multi-factor/validate`, {
    method: 'POST',
    body: JSON.stringify({ token, strategy, code }),
    headers: { 'Content-Type': 'application/json' }
  })

  if (!response.ok) throw new Error('An error has occurred')

  return response.text()
}

export { sendMultiFactorCode, validateMultiFactorCode }
