import styled from 'styled-components'

const ValidateEmailContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const ValidateEmailMessage = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #8c8b8c;
  font-size: 30px;
  font-weight: 700;
  background-color: #ebeef6;
  border: none;
  border-radius: 6px;
`

const RedirectContainer = styled.div`
  display: flex;
  width: 500px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  color: #000;
  font-size: 20px;
  font-weight: 300;
  background-color: #ebeef6;
  border: none;
  border-radius: 6px;

  a {
    color: #70b3a9;
    text-decoration: none;
    font-size: 40px;
    transition: 0.3s;

    &:hover {
      color: #e88437;
    }
  }
`

const LoadingMessage = styled.div`
  font-size: 20px;
  font-weight: 600;
`

export {
  ValidateEmailContainer,
  ValidateEmailMessage,
  LoadingMessage,
  RedirectContainer
}
