enum Title {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Miss = 'Miss',
  Ms = 'Ms',
  Mx = 'Mx',
  Dr = 'Dr',
  Prof = 'Prof'
}

export { Title }
