import { validateEmail } from 'actions/validateEmail'
import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  LoadingMessage,
  RedirectContainer,
  ValidateEmailContainer,
  ValidateEmailMessage
} from './styles'

const ValidateEmail = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [showRedirect, setShowRedirect] = useState(false)
  const [error, setError] = useState('')

  const handleValidateEmail = useCallback(
    (token: string) => {
      validateEmail({ token })
        .then(_ => {
          return toast(
            'Email validation successful. Please wait to be redirected',
            {
              type: 'success',
              onClose: () => {
                navigate('/select-app')
                setShowRedirect(true)
              }
            }
          )
        })
        .catch(e => {
          console.log(e)
          setError(
            'An error occurred while validating your email. Please try again or contact support'
          )
          return toast('Email validation failed', { type: 'error' })
        })
        .finally(() => setLoading(false))
    },
    [navigate]
  )

  useEffect(() => {
    const token = searchParams.get('token')
    if (token) {
      handleValidateEmail(token)
    } else {
      setLoading(false)
    }
  }, [searchParams, handleValidateEmail])

  return !searchParams.get('token') ? (
    <ValidateEmailContainer>
      <ValidateEmailMessage>No token found in URL</ValidateEmailMessage>
    </ValidateEmailContainer>
  ) : (
    <>
      {loading && (
        <ValidateEmailContainer>
          <LoadingMessage>Validating email...</LoadingMessage>
        </ValidateEmailContainer>
      )}
      {error && toast(`${error}`, { type: 'error' })}
      {showRedirect && (
        <ValidateEmailContainer>
          <RedirectContainer>
            <p>If you did not get redirected, please click</p>
            <Link to={'/select-app'}>here</Link>
          </RedirectContainer>
        </ValidateEmailContainer>
      )}
      {!loading &&
        !error &&
        !showRedirect &&
        toast('Success! Please wait to be redirected', { type: 'success' })}
    </>
  )
}

export default ValidateEmail
