import { StyledIcon } from '@styled-icons/styled-icon'
import { AlternateEmail, Sms, QrCode2 } from '@styled-icons/material'
import { MultiFactorStrategy } from 'types'

const strategyIcons: Record<MultiFactorStrategy, StyledIcon> = {
  [MultiFactorStrategy.Email]: AlternateEmail,
  [MultiFactorStrategy.SMS]: Sms,
  [MultiFactorStrategy.TOTP]: QrCode2
}

export { strategyIcons }
