import { AUTH_API_HOST } from 'const'

const validateEmail = async ({ token }: any): Promise<void> => {
  const validateEmailRequest = new Request(
    `${AUTH_API_HOST}/api/user/validate-email`,
    {
      method: 'POST',
      body: JSON.stringify({ token }),
      headers: {
        'Content-type': 'application/json'
      }
    }
  )

  const response = await fetch(validateEmailRequest)
  if (!response.ok) throw new Error('Email Validation Failed')
  return
}

export { validateEmail }
