import styled from 'styled-components'

const SelectAppContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`
const SelectAppLinkContainer = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #000;
  cursor: pointer;
  font-size: 20px;
  font-weight: 200;
  background-color: #ebeef6;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 15px;
  transition: 0.2s ease-in;

  &:hover {
    background-color: #b5b7ba;
  }
`
const SelectAppLink = styled.a`
  color: #000;
  text-decoration: none;
`

export { SelectAppContainer, SelectAppLinkContainer, SelectAppLink }
